import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import './header-connect.scss';

import { SendMetrik } from '../../Utils/YaMetriks/metriks';
import { Link } from 'react-router-dom';
import axios from 'axios';

const saveField = (e, setField) => {
    setField(e.target.value);
};
// eslint-disable-next-line no-empty-pattern
const Form = ({ }) => {
    const [phoneField, setPhoneField] = useState('');
    const [addressField, setAddressField] = useState('');
    const [personField, setPersonField] = useState(true);
    const [popupInfo, setPopupInfo] = useState({
        title: 'Форма успешно отправлена!',
        desc: ['В течении часа с вами свяжется оператор'],
    });
    const [isShowPopup, setIsShowPopup] = useState(false);

    const handlePopup = () => {
        setIsShowPopup((prev) => !prev);
    };

    const [validationFields, setValidationFields] = useState({
        phone: false,
        person: true,
    });

    useEffect(() => {
        validateForm();
    }, [phoneField, personField]);

    const validateForm = () => {
        let errors = [];

        if (phoneField.length < 4) {
            setValidationFields((prev) => ({ ...prev, phone: false }));
            errors.push('Телефон');
        } else {
            setValidationFields((prev) => ({ ...prev, phone: true }));
        }
        if (!personField) {
            setValidationFields((prev) => ({ ...prev, person: false }));
            errors.push('Согласие на обработку персональных данных и Политикой конфиденциальности');
        } else {
            setValidationFields((prev) => ({ ...prev, person: true }));
        }

        setPopupInfo({ title: 'Ошибка! Заполните следующие поля:', desc: errors });
    };

    const url = `${process.env.REACT_APP_DB_IP}/api/form`;

    const sendForm = (e) => {
        e.preventDefault();
        const finalAddress = addressField === '' ? 'Не указан' : addressField;




        if (
            validationFields.phone === true &&
            validationFields.person === true
        ) {
            axios
                .post(`${url}`, {
                    firstName: "Не указан",
                    phone: phoneField,
                    address: finalAddress,
                    tariffName: "Хочу другой тариф",
                })
                .then(function (response) {
                    setPopupInfo({
                        title: 'Форма успешно отправлена!',
                        desc: 'В течении часа с вами свяжется оператор',
                    });
                    handlePopup();
                    SendMetrik('reachGoal', 'SendForm');

                })
                .catch(function (error) {
                    setPopupInfo({ title: 'Ошибка', desc: error.response.data.message });
                    handlePopup();
                });
        } else {
            setIsShowPopup(true);
        }
    };

    return (
        <section className="connect">


            <div className={isShowPopup ? 'popup show' : 'popup'}>
                <div className="close-btn" onClick={() => handlePopup()}>
                    <div></div>
                    <div></div>
                </div>
                <p className="header_pop">{popupInfo?.title}</p>
                <p className="desc">
                    {typeof popupInfo.desc === 'object' ? (
                        <ul>
                            {popupInfo?.desc?.map((el) => {
                                return <li>{el}</li>;
                            })}
                        </ul>
                    ) : (
                        popupInfo?.desc
                    )}
                </p>
            </div>

            <div className="container">
                <h2>Оставить заявку</h2>
                <p className="description">Оставьте заявку сейчас и в течение часа мы подберём лучшего провайдера на вашем адресе!</p>
                <form action="#" className="connect-form" method="post">
                    <InputMask
                        mask="+7 999 999 99 99"
                        maskChar=""
                        type="text"
                        value={phoneField}
                        onChange={(e) => {
                            saveField(e, setPhoneField);
                        }}
                        placeholder="Номер телефона"
                    />

                    <input
                        type="text"
                        value={addressField}
                        className="connect-form-address"
                        placeholder="Адрес (необязателен)"
                        required
                        onChange={(e) => {
                            saveField(e, setAddressField);
                        }}
                    />

                    <div className="connect-form-checkbox">
                        <label htmlFor="header_acception">
                            <input
                                type="checkbox"
                                name="header_acception"
                                id="header_acception"
                                required
                                value={personField}
                                checked={personField}
                                onChange={(e) => {
                                    setPersonField((prev) => !prev);
                                }}
                            />
                            <div className="connect-form-checkbox-fake"></div>
                        </label>
                        <p className="connect-form-checkbox-description">
                            Нажимая кнопку "Отправить" Вы соглашайтесь с <br /> <Link to="/person"> политикой конфиденциальности.</Link>
                        </p>
                    </div>
                    <button
                        type="submit"
                        onClick={(e) => {
                            sendForm(e);
                        }}>
                        Отправить
                    </button>
                </form>
            </div>
        </section>
    );
};

export default Form;
