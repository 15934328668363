import React from 'react';
import './personality.scss';
import { Link } from 'react-router-dom';

const Personality = () => {
    return (
        <div className="personality">
            <Link className="btn" to="/">
                {'Назад'}
            </Link>
            <h1>Политика конфиденциальности</h1>
            <div>
                <p>
                    Администрация сайта с уважением относится к правам посетителей Сайта.
                    Мы безоговорочно признаем важность конфиденциальности личной информации посетителей нашего Сайта.
                </p>
                <p>&nbsp;</p>
                <p>
                    Посещая Сайт и отправив заявку Вы полностью соглашаетесь с данной Политикой конфиденциальности.
                </p>
                <p>&nbsp;</p>
                <p>
                    Основные положения нашей политики конфиденциальности формулируются следующим образом: <br />

                    Мы не передаем Вашу персональную информацию третьим лицам. <br />
                    Мы не передаем Вашу контактную информацию без Вашего согласия. <br />
                </p>
                <p>&nbsp;</p>
                <p>
                    Настоящее согласие распространяется на следующие Ваши персональные данные:
                    имя и контактный телефон, адрес для проверки подключения.
                </p>
                <p>
                    Персональная информация, предоставляемая Вами при регистрации на сайте, проверке на достоверность не подвергается. <br />
                    Техническая информация. Во время посещения вами Сайта, администрации Сайта автоматически становится доступной информация из стандартных журналов регистрации сервера (server logs). <br />
                    Сюда включается IP-адрес Вашего компьютера (или прокси-сервера, если он используется для выхода в Интернет), имя Интернет-провайдера, имя домена, тип браузера и операционной системы, информация о сайте,
                    с которого Вы совершили переход на Сайт, страницах Сайта, которые Вы посещаете, дате и времени этих посещений, файлах, которые Вы загружаете. Это информация анализируется нами в агрегированном (обезличенном) виде для анализа посещаемости Сайта,
                    и используется при разработке предложений по его улучшению и развитию.
                     Связь между Вашим IP-адресом и Вашей персональной информацией никогда не раскрывается третьим лицам,
                    за исключением тех случаев, когда это требует законодательство.
                </p>
                <p>&nbsp;</p>
                <p>
                    Использование полученной информации: <br />
                    Информация, предоставляемая Вами при заявке, <br />
                    а также техническая информация используется исключительно для улучшения работы сайта. <br />
                    Вся контактная информация, которую Вы нам предоставляете, раскрывается только с Вашего разрешения. <br />
                </p>
                <p>&nbsp;</p>
                <p>
                    Заключительные положения: <br />
                    Никакие из содержащихся здесь заявлений не означают заключения договора или соглашения между Владельцем Сайта и Пользователем, <br />
                    предоставляющим персональную информацию. <br />
                    Политика конфиденциальности лишь проинформирует Вас о подходах Сайта к работе с персональными данными. <br />
                </p>
                <p>&nbsp;</p>
                <p>
                    Вся информация на сайте носит исключительно ознакомительный характер и может отличаться. Все подробности уточняйте у оператора. <br />
                </p>
                <p>&nbsp;</p>
                <p>
                    Не является публичной офертой.
                </p>
            </div>
        </div>
    );
};

export default Personality;
