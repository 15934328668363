import React from 'react';
import './footer.scss';
import ScrollLinkOffset from '../Utils/ScrollFix/scrollOffset';
import { formatPhoneNumber } from '../Utils/PhoneFormat/formatPhoneNumber';



const Footer = ({ headerData }) => {

    const formattedPhones = [
        { number: headerData?.phone1, formatted: formatPhoneNumber(headerData?.phone1) },
        { number: headerData?.phone2, formatted: formatPhoneNumber(headerData?.phone2) }
    ];

    return (
        <footer className="footer">
            <div className="container footer-container">
                <ScrollLinkOffset />
                <nav className="footer-container-links">
                    <ul>
                        <li>
                            <a className="header_link" href="#top">Главная</a>
                        </li>
                        <li>
                            <a data-smooth-scroll className="header_link" href="#faq">FAQ</a>
                        </li>
                        <li>
                            <a data-smooth-scroll className="header_link" href="#tarrifs">Тарифы</a>
                        </li>
                        <li>
                            <a data-smooth-scroll className="header_link" href="#form">Подключиться </a>
                        </li>
                    </ul>
                </nav>
                <div className="footer-container-logo"></div>
                <nav className="footer-container-tels">
                    <ul>
                        {formattedPhones.map((phone, index) => (
                            <li key={index}>
                                <a className="header_link" href={`tel:+${phone.number}`} dangerouslySetInnerHTML={{ __html: `+${phone.formatted}` }} />
                            </li>
                        ))}
                    </ul>
                    <div className="footer-container-socials">
                        <a className="header_link" href={`https://t.me/+${headerData?.phone1}`} target="_blank" rel="noopener noreferrer">
                            <div className=" icon footer-container-socials-telegram"></div>
                        </a>
                        <a className="header_link" href={`https://wa.me/+${headerData?.phone1}`} target="_blank" rel="noopener noreferrer">
                            <div className=" icon footer-container-socials-whatsapp"></div>
                        </a>
                    </div>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;
