// import axios from 'axios';
import React, { useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes, Navigate  } from 'react-router-dom';
import Main from './pages/main';
import Personality from './pages/personality';
import ScrollToTop from './components/Utils/ScrollFix/scrollTop';

function App() {
    useEffect(() => {
        document.documentElement.style.colorScheme = 'only light';
      }, []);
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {' '}
                <Route path="/" element={<Main />} />
                <Route path="/person" element={<Personality />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
