import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import './header.scss';
import Form from './Form/form';
import ScrollLinkOffset from '../Utils/ScrollFix/scrollOffset';
import { formatPhoneNumber } from '../Utils/PhoneFormat/formatPhoneNumber';

const Header = ({ headerData }) => {
    const [activeMenu, setActiveMenu] = useState(false);
    const menuRef = useRef(null);

    const handleMenuClick = () => {
        setActiveMenu((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setActiveMenu(false);
        }
    };

    const handleLinkClick = () => {
        setActiveMenu(false);
    };

    useEffect(() => {
        if (activeMenu) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [activeMenu]);

    const formattedPhones = [
        { number: headerData?.phone1, formatted: formatPhoneNumber(headerData?.phone1) },
        { number: headerData?.phone2, formatted: formatPhoneNumber(headerData?.phone2) }
    ];

    return (
        <>
            <main className="color_theme">
                <div className="container">
                    <ScrollLinkOffset />
                    <header className="header">
                        <nav className="header-navigations" ref={menuRef}>
                            <a href="#top">
                                <div className="header-navigations-logo"></div>
                            </a>
                            <ul
                                className={
                                    isMobile
                                        ? activeMenu
                                            ? 'header-navigations-links-mobile open'
                                            : 'header-navigations-links-mobile'
                                        : 'header-navigations-links'
                                }
                            >
                                <li className="link">
                                    <a className="header_link" href="#top" onClick={handleLinkClick}>Главная</a>
                                </li>
                                <li className="link">
                                    <a data-smooth-scroll className="header_link" href="#tarrifs" onClick={handleLinkClick}>Тарифы</a>
                                </li>
                                <li className="link">
                                    <a data-smooth-scroll className="header_link" href="#faq" onClick={handleLinkClick}>FAQ</a>
                                </li>
                                <li className="link">
                                    <a data-smooth-scroll className="header_link" href="#connect" onClick={handleLinkClick}>Подключиться</a>
                                </li>
                                <li>
                                    {isMobile ? (
                                        <div className="header-navigations-phones">
                                            <ul className="header-navigations-phones-links">
                                                {formattedPhones.map((phone, index) => (
                                                    <li key={index}>
                                                        <a className="header_link" href={`tel:+${phone.number}`} dangerouslySetInnerHTML={{ __html: `+${phone.formatted}` }} onClick={handleLinkClick} />
                                                    </li>
                                                ))}
                                            </ul>
                                            <a href={`https://t.me/+${headerData?.phone1}`} onClick={handleLinkClick}>
                                                <div className="icon header-navigations-phones-telegram"></div>
                                            </a>
                                            <a href={`https://wa.me/+${headerData?.phone1}`} onClick={handleLinkClick}>
                                                <div className="icon header-navigations-phones-whatsapp"></div>
                                            </a>
                                        </div>
                                    ) : null}
                                </li>
                            </ul>
                            {!isMobile ? (
                                <div className="header-navigations-phones">
                                    <ul className="header-navigations-phones-links">
                                        {formattedPhones.map((phone, index) => (
                                            <li key={index}>
                                                <a className="header_link" href={`tel:+${phone.number}`} dangerouslySetInnerHTML={{ __html: `+${phone.formatted}` }} />
                                            </li>
                                        ))}
                                    </ul>
                                    <a href={`https://t.me/+${headerData?.phone1}`} target="_blank" rel="noopener noreferrer">
                                        <div className="icon header-navigations-phones-telegram"></div>
                                    </a>
                                    <a href={`https://wa.me/+${headerData?.phone1}`} target="_blank" rel="noopener noreferrer">
                                        <div className="icon header-navigations-phones-whatsapp"></div>
                                    </a>
                                    <a href="#connect" className="btn">
                                        Подключиться
                                    </a>
                                </div>
                            ) : null}

                            {isMobile ? (
                                <div
                                    onClick={() => handleMenuClick()}
                                    className={
                                        activeMenu
                                            ? 'header-navigations-burger-menu open'
                                            : 'header-navigations-burger-menu'
                                    }
                                >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : null}
                        </nav>
                    </header>
                    <div className="text start_page" id="header">
                        <div className="grid_block">
                            <h1>{headerData?.title}</h1>
                            <p className="header_text">
                                {headerData?.sub}
                            </p>
                            {headerData?.connect || headerData?.time || headerData?.phone2 ? (
                                <p>
                                    <h5>
                                        {headerData?.connect} <br /> <br />
                                        {headerData?.time || headerData.user} <br /> <br />
                                        {headerData?.users} <br /> <br />
                                        <div>
                                            <a className='header_link' href={`tel:+${headerData?.phone2}`} dangerouslySetInnerHTML={{ __html: `+${formatPhoneNumber(headerData?.phone2)}` }} />
                                        </div>
                                    </h5>
                                </p>
                            ) : null}
                        </div>
                        <div className="grid_block" id="form">
                            <Form />
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Header;
