import { useEffect } from 'react';

const ScrollLinkOffset = () => {
    useEffect(() => {
        const handleScroll = (event) => {
            const target = event.target.closest('a[data-smooth-scroll]');
            if (target) {
                event.preventDefault();
                const targetId = target.getAttribute('href').substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                    const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                    const offsetPosition = elementPosition - 20;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });

                    window.history.pushState(null, '', `#${targetId}`);
                }
            }
        };

        document.addEventListener('click', handleScroll);

        return () => {
            document.removeEventListener('click', handleScroll);
        };
    }, []);

    return null;
};

export default ScrollLinkOffset;
